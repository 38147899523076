var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header',[_c('SearchField',{staticClass:"font-weight-black",model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',[_c('BtnIcon',{staticClass:"mr-1",on:{"click":function($event){_vm.filter = !_vm.filter}}},[_vm._v("filter_alt")]),_c('BtnAdd',{attrs:{"to":"/actividad/add"}})],1)],1),_c('div',[(_vm.filter)?_c('FilterAdvanced',{attrs:{"params":_vm.getParams,"value":_vm.selected},on:{"fetch":function($event){return _vm.fetch()},"pdf":function($event){return _vm.generarPDF()}}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.desserts,"custom-filter":_vm.customFilter,"loading":_vm.isLoading,"header-props":_vm.headerProps,"items-per-page":9999999,"show-select":_vm.filter,"item-key":"idactividad","hide-default-footer":""},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"item.idestadocobro.idestadocobro",fn:function(ref){
var item = ref.item;
return [_c('c-chip',{attrs:{"dark":"","color":_vm.color(item.idestadocobro.idestadocobro)}},[_vm._v(_vm._s(item.idestadocobro.descripcion))])]}},{key:"item.detalle",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDetalle(item.detalle)))])]}},{key:"item.tecnico",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatTecnico(item.tecnico)))])]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":item.idestadocobro.idestadocobro !== 1,"disabled":item.idestadocobro.idestadocobro !== 1,"ripple":false},on:{"input":function($event){return select($event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","x-small":"","text":"","elevation":"2","color":"primary"},on:{"click":function($event){return _vm.setData(item)}}},[_c('c-icon',[_vm._v(" arrow_forward_ios ")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }